
// const cdnURl = 'https://d3rodw1h7g0i9b.cloudfront.net';
const config = {
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  origin: [
    process.env.NEXT_PUBLIC_API_URL,
    'http://127.0.0.1',
    'http://localhost:3000',
    'http://127.0.0.1:8000',
    'http://104.142.122.231'
  ],
  proxy: {
    host: process.env.NEXT_PUBLIC_API_URL,
    port: 443
  },
  withCredentials: true,
  // credentials: true,
  exposedHeaders: ['set-cookie', 'Authorization']
};

const default_headers = {
  'Content-Type': 'application/json;charset=utf-8'
};
const defaults_timeout = 50000;


export { config, default_headers, defaults_timeout };
