"use client";
import { useEffect, useRef } from "react";
import { isElementVisible } from "@/lib/utils";

const GtmPromotion = () => {
  const scrollTimer = useRef();
  useEffect(() => {

    const handleScroll = (e) => {
        clearTimeout(scrollTimer.current);
        scrollTimer.current  = setTimeout(() => {
            const visibleElements = document.querySelectorAll(".promotion.visible:not(.viewed)");
            visibleElements.forEach(node => {
                const isVisible = isElementVisible(node);
                if(isVisible){
                    node.classList.add('viewed');
                    if(node.dataset){
                        gtag("event", "view_promotion", {
                            item_name : node.dataset.pname,
                            item_id : node.dataset.piid,
                            item_category : node.dataset.picategory,
                            item_brand : node.dataset.pibrand
                        });
                    }
                }
            })
        }, 500)
    }

    document.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      document.removeEventListener('scroll', handleScroll, { passive: true })
      clearTimeout(scrollTimer.current)
    }
  }, [])
  return null
}

export default GtmPromotion