import axios from 'axios';
import { 
  setHeadersInStorage, 
  addAuthTokenToRequest,
  forcedLogout
} from './utils';
import {
  config, default_headers, defaults_timeout
} from './config';

let store;
export const injectStore = (_store) => {
  store = _store;
};

const instance = axios.create(config);
instance.defaults.headers = default_headers;
axios.defaults.timeout = defaults_timeout;

instance.interceptors.response.use(
  (response) => {
    try {
      setHeadersInStorage(response.headers, store.dispatch);
    } catch (e) {
      console.error(e);
    }
    return response;
  },
  (error) => {
    if(error.response){
      if(error.response.status == 401){
        return forcedLogout(store.dispatch)
      }
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  async (config) => {
    return await addAuthTokenToRequest(config)
  },
  (err) => {
    return Promise.reject(err);
  }
);

export { instance, store };
