"use client";
import { useEffect } from "react";
import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import Stars from "@/components/CustomUI/modules/Stars";
import { zapfHumanistRegular } from "@/app/jewellery/font";

const Testimony = ({ data, uniqueKey }) => {

  const renderItems = () => {
    return data.map((item, i) => (
      <div
        key={`${item.review.name}_${uniqueKey}${i}`}
        className={`
          ${zapfHumanistRegular.className} 
          flex flex-col md:flex-row 
          flex-[0_0_100%] snap-start
          ml-[2px]
        `}
      >
        <div 
          className="
            w-full md:w-[50%] flex justify-center 
            md:justify-end items-center
          "
        >
          <img
            src={item.image.src}
            alt={item.image.alt}
            width={item.image.width}
            height={item.image.height}
            loading="lazy"
            decoding="async"
            style={{ width: item.image.width, height: "auto", aspectRatio: 1 }}
          />
        </div>
        <div 
          className="
            w-full md:w-[50%] px-5 px-[10%] md:px-[5%] lg:px-[10%] flex flex-col 
            text-center justify-center items-center mt-4 md:mt-0
          "
        >
        <p 
          className="
            font-black text-base md:text-xl desktop:text-2xl
          "
        >
          Testimonials
        </p>
        <DropdownMenuSeparator className="bg-[#cdcdcd] w-full h-[1px] mb-3" />
          <Stars
            totalRating={5}
            ratingValue={item.review.value}
            starStyle={{ height: 22, width: 22 }}
            uniqueKey={"testimony_stars_"+ i}
            containerStyle={{ justifyContent: "center" }}
          />
          <article className="mx-2 mt-3  text-sm md:text-base lg:text-lg desktop:text-xl leading-5	">
            <p 
              className="
                whitespace-break-spaces font-medium
              "
            >
              {item.review.text}
            </p>
            <p 
              className="
                text-[#33b5b6] drop-shadow-sm 
                font-black mt-1 tracking-wider
              "
            >
              {item.review.name}
            </p>
          </article>
        </div>
      </div>
    ))
  }

  useEffect(() => {
    new Glider(document.querySelector("." + uniqueKey), {
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      scrollLock: true,
      scrollLockDelay: 400,
      resizeLock: true,
      dragVelocity: 1.8,
      duration: 1,
      arrows: {
        prev: `.${uniqueKey}_arrow_prev`,
        next: `.${uniqueKey}_arrow_next`
      },
      responsive: [
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    });
  }, [uniqueKey])

  return (
    <section className="md:mx-[8%] mt-[6%] desktop:mx-[7%] dekstop:mt-[5%]" style={{ position: "relative" }}>
      <div
        role="button" aria-label="Previous Testimony"
        className={`${uniqueKey}_arrow_prev custom-glider-btn`}
        aria-disabled="true"
        style={{ left: "3%", position: "absolute",  }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 120.64 122.88"
          style={{ enableBackground: "new 0 0 120.64 122.88" }}
          xmlSpace="preserve"
          fill="#464646"
          width={30}
          height={30}
          className="glider-svg-button w-[20px] h-[20px] md:w-[30px] md:h-[30px]"
        >
          <g>
            <path d="M66.6,108.91c1.55,1.63,2.31,3.74,2.28,5.85c-0.03,2.11-0.84,4.2-2.44,5.79l-0.12,0.12c-1.58,1.5-3.6,2.23-5.61,2.2 c-2.01-0.03-4.02-0.82-5.55-2.37C37.5,102.85,20.03,84.9,2.48,67.11c-0.07-0.05-0.13-0.1-0.19-0.16C0.73,65.32-0.03,63.19,0,61.08 c0.03-2.11,0.85-4.21,2.45-5.8l0.27-0.26C20.21,37.47,37.65,19.87,55.17,2.36C56.71,0.82,58.7,0.03,60.71,0 c2.01-0.03,4.03,0.7,5.61,2.21l0.15,0.15c1.57,1.58,2.38,3.66,2.41,5.76c0.03,2.1-0.73,4.22-2.28,5.85L19.38,61.23L66.6,108.91 L66.6,108.91z M118.37,106.91c1.54,1.62,2.29,3.73,2.26,5.83c-0.03,2.11-0.84,4.2-2.44,5.79l-0.12,0.12 c-1.57,1.5-3.6,2.23-5.61,2.21c-2.01-0.03-4.02-0.82-5.55-2.37C89.63,101.2,71.76,84.2,54.24,67.12c-0.07-0.05-0.14-0.11-0.21-0.17 c-1.55-1.63-2.31-3.76-2.28-5.87c0.03-2.11,0.85-4.21,2.45-5.8C71.7,38.33,89.27,21.44,106.8,4.51l0.12-0.13 c1.53-1.54,3.53-2.32,5.54-2.35c2.01-0.03,4.03,0.7,5.61,2.21l0.15,0.15c1.57,1.58,2.38,3.66,2.41,5.76 c0.03,2.1-0.73,4.22-2.28,5.85L71.17,61.23L118.37,106.91L118.37,106.91z" />
          </g>
        </svg>
      </div>
      <div
        role="button"
        aria-label="Next Testimony"
        className={`${uniqueKey}_arrow_next custom-glider-btn glider-next`}
        aria-disabled="true"
        style={{ right: "3%", position: "absolute",  }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 120.64 122.88"
          style={{ enableBackground: "new 0 0 120.64 122.88" }}
          xmlSpace="preserve"
          fill="#464646"
          width={30}
          height={30}
          className="glider-svg-button w-[20px] h-[20px] md:w-[30px] md:h-[30px]"
        >
          <g>
            <path d="M54.03,108.91c-1.55,1.63-2.31,3.74-2.28,5.85c0.03,2.11,0.84,4.2,2.44,5.79l0.12,0.12c1.58,1.5,3.6,2.23,5.61,2.2 c2.01-0.03,4.01-0.82,5.55-2.37c17.66-17.66,35.13-35.61,52.68-53.4c0.07-0.05,0.13-0.1,0.19-0.16c1.55-1.63,2.31-3.76,2.28-5.87 c-0.03-2.11-0.85-4.21-2.45-5.8l-0.27-0.26C100.43,37.47,82.98,19.87,65.46,2.36C63.93,0.82,61.93,0.03,59.92,0 c-2.01-0.03-4.03,0.7-5.61,2.21l-0.15,0.15c-1.57,1.58-2.38,3.66-2.41,5.76c-0.03,2.1,0.73,4.22,2.28,5.85l47.22,47.27 L54.03,108.91L54.03,108.91z M2.26,106.91c-1.54,1.62-2.29,3.73-2.26,5.83c0.03,2.11,0.84,4.2,2.44,5.79l0.12,0.12 c1.57,1.5,3.6,2.23,5.61,2.21c2.01-0.03,4.02-0.82,5.55-2.37C31.01,101.2,48.87,84.2,66.39,67.12c0.07-0.05,0.14-0.11,0.21-0.17 c1.55-1.63,2.31-3.76,2.28-5.87c-0.03-2.11-0.85-4.21-2.45-5.8C48.94,38.33,31.36,21.44,13.83,4.51l-0.12-0.13 c-1.53-1.54-3.53-2.32-5.54-2.35C6.16,2,4.14,2.73,2.56,4.23L2.41,4.38C0.84,5.96,0.03,8.05,0,10.14c-0.03,2.1,0.73,4.22,2.28,5.85 l47.18,45.24L2.26,106.91L2.26,106.91z" />
          </g>
        </svg>
      </div>
      <div 
        className={`glider ${uniqueKey} scrollbar-hide`}
        style={{ display: "flex", overflowX: "scroll" }}  
      >
        {renderItems()}
      </div>
    </section>
  )
}

export default Testimony