"use client";

import "./index.css";

const Stars = ({ 
  totalRating, ratingValue, uniqueKey, 
  starStyle, containerStyle, handleClick,
  starClasses
}) => {
  if (!totalRating) return null;

  const renderItem = () => {
    return new Array(totalRating).fill("").map((_, i) => {
      const value = ratingValue - i;
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          style={starClasses ? { width: 11, height: 11 } : { ...starStyle }}
          key={"stars" + i}
          onClick={() => handleClick ? handleClick(i) : null}
          className={starClasses}
        >
          <defs>
            <linearGradient
              id={`grad${i + ratingValue + "_" + uniqueKey}`}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              {value >= 1 ? (
                <stop offset={"100%"} stopColor={"#45d4d5"} />
              ) : value > 0 ? (
                <>
                  <stop offset={`${value * 100}%`} stopColor={"#45d4d5"} />
                  <stop offset={"0%"} stopColor={"#cccccc"} />
                  <stop
                    offset={`${100 - value * 100}%`}
                    stopColor={"#cccccc"}
                  />
                </>
              ) : (
                <stop offset={"100%"} stopColor={"#cccccc"} />
              )}
            </linearGradient>
          </defs>
          <path
            fill={`url(#grad${i + ratingValue + "_" + uniqueKey})`}
            strokeWidth={0}
            className={ uniqueKey === "no_shadow" ? "" : "post_stars_" + i}
            d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,
              31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118
              l11.547-1.2L16.026,0.6L20.388,10.918z"
          />
        </svg>
      );
    });
  };

  return (
    <div 
      className="flex gap-1 w-full"
      style={{ ...containerStyle }}
    >
      {renderItem()}
    </div>
  );
};

export default Stars;
