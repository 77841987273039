"use client";
import { useEffect, useCallback } from "react";
import Link from "next/link";
import { zapfHumanistRegular } from "@/app/jewellery/font";

const Carousel = ({ data, uniqueKey }) => {
  const { title, link, items } = data;
  const totalItems = items.length;
  let slickViewTimer = null;
  
  const renderItems = () => {
    return items.map((item, i) => (
      <Link
        href={item.url}
        key={`${item.url}_${uniqueKey}_${i}`}
        className={`mr-3 carousel-div zoomable group overflow-hidden  ${item.forPromotion ? 'promotion' : ""}`}
        onClick={() => handlePress(item)}
        data-pname={item.forPromotion ? item.promotion.promotion_name : null}
        data-piname={item.forPromotion ? item.promotion.items[0].item_name : null}
        data-piid={item.forPromotion ? item.promotion.items[0].item_id : null}
        data-picategory={item.forPromotion ? item.promotion.items[0].item_category : null}
        data-pibrand={item.forPromotion ? item.promotion.items[0].item_brand : null}
      >
        <img
          src={item.image.src}
          alt={item.image.alt}
          width={item.image.width}
          height={item.image.height}
          style={{ height: 'auto', width: totalItems > 4 ? "100%" : "21vw" }}
          loading="lazy"
          decoding="async"
          className={"zoomable-img"}
        />
        <div
          className="
              relative z-10 bg-[#f0fafa] 
              py-3 mb-[-3px]
            "
        >
          <p
            className="
                group-hover:text-[#33b5b6] 
                text-sm md:text-base xl:text-lg desktop:text-xl 
                drop-shadow-sm font-black
                whitespace-break-spaces
                leading-3	

              "
          >
            {item.name}
          </p>
        </div>
      </Link>
    ))
  }

  const handlePress = useCallback((data) => {
    if (data.forPromotion) {
      return gtag("event", "select_promotion", data.promotion);
    } else if (data.isBanner) {
      return gtag("event", "select_banner", { banner_name: data.banner_name });
    }
    return
  }, [])

  const isCelebInspired = useCallback((title) => {
    if (title.toLowerCase().includes("celebrity")) return "celebrityInspired";
    return "";
  }, [])

  const handleScroll = (e) => {
    if(slickViewTimer !== null) {
      clearTimeout(slickViewTimer);        
  }

  slickViewTimer = setTimeout(() => {
      const currentElement = e.target.querySelector(".promotion.active.visible:not(.viewed)");
      if(currentElement){
          currentElement.classList.add("viewed");
          const promoData = data.items[currentElement.dataset.gslide].promotion
          if(promoData) gtag("event", "view_promotion", promoData);
      }
  }, 500);
  }

  useEffect(() => {

    new Glider(document.querySelector("." + uniqueKey), {
      slidesToShow: 4.5,
      slidesToScroll: 4.5,
      draggable: true,
      scrollLock: true,
      scrollLockDelay: 400,
      resizeLock: true,
      dragVelocity: 1.8,
    });
  }, [uniqueKey])

  return (
    <section
      className={`
        ${zapfHumanistRegular.className} 
        ml-[8%] mt-[6%] desktop:ml-[7%] dekstop:mt-[5%]`
      }
    >
      {
        title &&
        <div
          className="mb-3 mr-[6%]"
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <h2
            className="
              text-base md:text-lg lg:text-xl desktop:text-2xl font-black
              drop-shadow-sm
            "
            id={isCelebInspired(title)}
          >
            {title}
          </h2>
          {
            link &&
            <Link
              href={link.url}
              className="
                text-base xl:text-lg desktop:text-xl font-black
                text-[#000] underline font-extrabold
              "
            >
              {link.text}
            </Link>
          }
        </div>
      }
      <nav 
        className={`glider ${uniqueKey} scrollbar-hide`}
        onScroll={handleScroll}
        style={{ display: "flex", overflowX: "scroll" }}
      >
        {renderItems()}
      </nav>
    </section>
  )
}

export default Carousel