import { toast } from 'react-toastify';
import { 
  setHeaderInfo, setCurrencyMeta
} from "@/store/general/generalSlice";


export const forcedLogout = async (dispatch) => {
  await handleLogout(dispatch);
};

export const setHeadersInStorage = (headers, dispatch) => {
  dispatch(
    setHeaderInfo({
      cartCount: headers.cart_count,
      wishlistCount: headers.wishlist_count,
    })
  );
  dispatch(
    setCurrencyMeta({
      rate: Number(headers.c_rate), 
      code: headers.c_code
    })
  )
  if (headers.csrftoken) {
    localStorage.setItem("csrftoken", headers.csrftoken);
  }
  if (headers.c_rate) {
    localStorage.setItem("c_rate", headers.c_rate);
  }
  if (headers.c_code) {
    localStorage.setItem("c_code", headers.c_code);
  }
  if (headers.version) {
    localStorage.setItem("version", headers.version);
  }
};

export const addAuthTokenToRequest = (config) => {
  const user_info = localStorage.getItem("user_info");
  const csrftoken = localStorage.getItem("csrftoken");
  if (user_info) {
    let token = JSON.parse(user_info).token;
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
  }
  if (csrftoken) {
    config.headers["X-CSRFToken"] = csrftoken;
  }
  return config;
};

export const handleErrors = (error) => {
  let errorMessage;
  if (error.response && error.response.data) {
    if (error.response.data.error_msg) {
      errorMessage = error.response.data.error_msg;
    } else if (
      error.response.data.non_field_errors &&
      error.response.data.non_field_errors.length > 0
    ) {
      errorMessage = error.response.data.non_field_errors[0];
    } else if (error.response.status) {
      errorMessage = error.response.status;
    }
  }
  if (errorMessage !== undefined) {
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
};
