import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerInfo: {
    cartCount: 0,
    wishlistCount: 0
  },
  currencyMeta: { rate: 1, code: 'INR' },
  user: null,
  expertInfo: null,
  utm: null,
}


const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setHeaderInfo: (state, action) => {
      state.headerInfo = action.payload;
    },
    setCurrencyMeta: (state, action) => {
      state.currencyMeta = action.payload;
    },
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
    setExpertInfo: (state, action) => {
      state.expertInfo = action.payload;
    },
    setUtmInfo: (state, action) => {
      state.utm = action.payload;
    },
    setCurrency: (state, action) => {
      state.currencyMeta = action.payload;
    },
    setNewsletterStatus: (state, action) => {
      state.user = { ...state.user, is_newsletter_subscribed: action.payload };
    },
  },
});

export const {
  setHeaderInfo, setCurrencyMeta, setUserInfo,
  setExpertInfo, setUtmInfo, setNewsletterStatus
} = generalSlice.actions;
export default generalSlice.reducer;
