import { setExpertInfo } from "@/store/general/generalSlice";
import { instance } from "@/clientSideApi";
import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

const category_slugs = [
  "products", "rings", "earrings", "necklaces"
]

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const getExpertInfo = async (dispatch) => {
  const expert_info = localStorage.getItem("expert_info");
  if (expert_info) {
    const data = JSON.parse(expert_info)
    dispatch(setExpertInfo(data));
  }
}

export const getUtmQuery = () => {
  const queryString = window.location.search;
  if (!queryString) {
    return null
  }
  const utmQuery = queryString.replace("?", "").split("&").filter(query => query.includes("utm_")).join("&")
  if (utmQuery.length) {
    return utmQuery
  };
  return null
}

export const axiosBaseQuery = async (data, args) => {
  let { url, method, body } = data;
  let apiService;
  if (!url) {
    url = data;
    method = "GET"
  }

  switch (method) {
    case "GET":
      apiService = instance.get;
      break;
    case "POST":
      apiService = instance.post;
      break;
    case "PUT":
      apiService = instance.put;
      break;
    case "DELETE":
      apiService = instance.delete
      break
    default:
      apiService = instance.options;
  }

  try {
    const res = await apiService(url, body);
    const apiResponse = await res.data;
    return { data: apiResponse }
  } catch (error) {
    let err = error;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    }
  }
}

export const getLoggingPayload = (url) => {
  const data = { url };
  const urlArr = url.split("/");
  if (urlArr.length > 2) {
    if (url.includes("/jewellery") && category_slugs.includes(urlArr[2])) {
      data["category_slug"] = urlArr[2];
    } else if (
      category_slugs.includes(urlArr[1]) &&
      urlArr[2].split("-")[0].length
    ) {
      data["url_name"] = urlArr[2].split("-")[0];
    }
  }
  return data;
};

export const convertSearchParamsToString = (searchParams, currentKey, value) => {
  let search = "";
  for (const [key, value] of searchParams.entries()) {
    if (key !== currentKey && key !== "page") {
      search += `${key}=${value}&`
    }
  }
  if (search.length > 0) {
    return search + `${currentKey}=${value}`
  }
  return `${currentKey}=${value}`
}

export const getURLSearchParamsEntries = (searchParams) => {
  const result = {}
  for (const [key, value] of searchParams.entries()) {
    if (result.hasOwnProperty(key)) {
      result[key] = [...result[key], value]
    } else {
      result[key] = [value]
    }
  }
  return result
}

export const createParamsStringFromFilters = (searchParams) => {
  let search = ""
  for (const key in searchParams) {
    if (key !== "page") {
      const value = searchParams[key]
      if (!Array.isArray(value)) {
        search += `&${key}=${value}`
      } else {
        let temp = ""
        for (const val of value) {
          temp += `&${key}=${val}`
        }
        search += temp;
      }
    }
  }
  return search.slice(1,);
}

export const arraysHaveSameElements = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const frequency = {};

  // Count frequency of each element in array1
  for (const element of array1) {
    frequency[element] = (frequency[element] || 0) + 1;
  }

  // Count frequency of each element in array2 and decrement
  for (const element of array2) {
    if (!frequency[element]) {
      return false;
    }
    frequency[element] -= 1;
  }

  // Check if any count is not equal to zero
  for (const count of Object.values(frequency)) {
    if (count !== 0) {
      return false;
    }
  }

  return true;
};

export const shareApi = async ({ title, text, url }) => {
  if (window.navigator.share) {
    await window.navigator.share({
      title,
      text,
      url,
    })

  }
};

export const getPdpApiData = (rawData) => {
  const finalData = {};
  Object.keys(rawData).forEach((key) => {
    const value = rawData[key];
    if (value !== null) {
      if (key === "metal_carat") {
        finalData["metal_carat"] = value.split("_")[0];
      } else {
        finalData[key] = value;
      }
    } else {
      if (key === "size") finalData["size"] = null;
    }
  });
  return finalData;
}

export const validateEmail = (email) => {
  if (email.length === 0) return true;
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const generalEcomEvent = ({ event, items, other }) => {
  if (dataLayer === undefined || !dataLayer) return;
  dataLayer.push({ event, ecommerce: { ...other, items } });
}

export function isElementVisible(element) {
  if (!element) return false;
  const elementBoundingBox = element.getBoundingClientRect();
  const elementTopY = elementBoundingBox.top;
  const elementBottomY = elementBoundingBox.top + elementBoundingBox.height;
  return elementTopY >= 0 && elementBottomY <= Math.min(document.documentElement.clientHeight, window.innerHeight || 0);
}

export const getEstimatedDate = (days) => {
  let currentDate = new Date();
  const dateString = new Date(
    currentDate.setDate(currentDate.getDate() + days)
  ).toDateString();
  const dateArr = dateString.split(" ").slice(0, 3);
  dateArr[0] = dateArr[0] + ",";
  return dateArr.join(" ");
};

export const getCurrentDiamondShape = (basic_details, output) => {
  if(!basic_details || !output) return null;
  if (basic_details.shape_list && basic_details.shape_list?.length > 0) {
    return basic_details.shape_list[0];
  }
  if (output?.center_stones?.shape) return output?.center_stones?.shape;
  return output?.side_diamonds?.shape
}

export const getCurrentDiamondColorClarity = (initialChoices, output) => {
  if (initialChoices) {
    if (initialChoices.diamond_color && initialChoices.diamond_clarity) {
      return initialChoices.diamond_clarity + "-" + initialChoices.diamond_color;
    }
    if (initialChoices.diamond_type) return initialChoices?.diamond_type;
  }
  if (output) {
    if (output.center_stones) {
      return output.center_stones.clarity + "-" + output.center_stones.color;
    }
    if (output.side_diamonds) {
      return output.side_diamonds.clarity + "-" + output.side_diamonds.color;
    }
  }
  return "Unsupported"
}

export const getProductCategory = (basic_details) => {
  if (basic_details?.categoryName) return basic_details?.categoryName?.slice(0, -1)?.toUpperCase();
  return "";
}

export const getDiscountsArr = (discount) => {
  const diamondDiscounts = discount.center_stones === discount.side_diamonds;
  const discounts = [
    {
      val: discount.gemstones,
      label: `${discount.gemstones}% OFF on GemStones`,
    },
    {
      val: discount.making,
      label: `${discount.making}% OFF on Making`,
    },
    {
      val: discount.other_raw_materials,
      label: `${discount.other_raw_materials}% OFF on Others`,
    },
    {
      val: discount.flat_percentage,
      label: `FLAT ${discount.flat_percentage}% OFF`,
    },
  ];

  if (diamondDiscounts) {
    discounts.push({
      val: discount.center_stones,
      label: `${discount.center_stones}% OFF on Diamonds`,
    });
  } else {
    discounts.push({
      val: discount.center_stones,
      label: `${discount.center_stones}% OFF on Center Stones`,
    });
    discounts.push({
      val: discount.side_diamonds,
      label: `${discount.side_diamonds}% OFF on Side Diamonds`,
    });
  }

  return discounts;
}

export const makeInitialTimelineState = (data) => {
  let temp = {};
  data.forEach((ele, i) => {
    if (ele.is_finished) {
      temp[`currentStage_${i}`] = true;
    } else {
      temp[`currentStage_${i}`] = false;
    }
  });
  return temp;
};

export const getYMDDateFormat = (date) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  // Add leading zero for months/days less than 10
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
}


export const getTodayDate = () => {
  const today = new Date();
  return today
}